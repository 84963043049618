<template>
  <el-container class="father">
    <!-- 头部区域 -->
    <el-header>
      <div class="header-top">
        <div class="header-left">
         <img src="../../fonts/logo@2x.png" alt />
          <span class="title">湖南省96320转运车管理系统</span>
        </div>
        <div class="header-center">
          <div class="home" v-if="home.length!==0">
            <img src="../../fonts/home@2x.png" alt />
            <span @click="$router.push(home[0].menuUrl)">首页</span>
          </div>
          <div class="operation" v-if="operation.length!==0">
            <img src="../../fonts/operation@2x.png" alt />
            <span @click="$router.push(operation[0].menuUrl)">运营管理</span>
          </div>
          <div class="setting" v-if="system.length!==0">
            <img src="../../fonts/settings@2x.png" alt />
            <span @click="$router.push(system[0].menuUrl)">业务管理</span>
          </div>
        </div>
        <div class="header-right">
          <img src="../../fonts/user@2x.png" alt />
          <span>欢迎&nbsp;{{ userName }}</span>
          <span @click="editUserTap()"  class="exit">修改密码</span>
          <span @click="exit()" class="exit">退出登录</span>
        </div>
      </div>
      <div class="header-under">
        <!-- <div class="under-left">
                    <i></i>
                    <span>菜单导航</span>
                    <span v-if="title !== ''">{{ title }}</span>
        </div>-->
        <i></i>
        <el-breadcrumb separator="|">
          <el-breadcrumb-item ><span @click="statusEliminate($route.meta.bread.title)">{{ $route.meta.bread.title }}</span></el-breadcrumb-item>
          <el-breadcrumb-item v-if="$route.meta.bread.children">
            {{
            $route.meta.bread.children.title
            }}
          </el-breadcrumb-item>
          <el-breadcrumb-item
            v-if="$route.meta.bread.children && $route.meta.bread.children.children"
          >
            {{
            $route.meta.bread.children.children.title
            }}
          </el-breadcrumb-item>
        </el-breadcrumb>
         <el-button v-if="largeScreen.length!==0" type="warning" class="header-under-btn" @click="toBigscreen"><span>大屏</span></el-button>

      </div>
    </el-header>
    <!-- 主体区域 -->
    <el-container class="main">
      <router-view />
    </el-container>
    <!-- 底部区域 -->
    <el-footer>
      <div class="footer">湖南96320服务平台 Copyright 2013-2024 ©版权所有 湘ICP备2020023409号-1</div>
    </el-footer>
     <!-- 修改密码弹出框 -->
      <el-dialog
        :visible.sync="editUserFormVisible"
        class="edit-dialog layout-modify"
      >
        <span slot="title" class="dialog-Title">修改用户密码</span>
        <el-form  :model="editUserForm" inline ref="editForms" :rules="editRules">
          <!-- 第一行 -->
          <el-row>
            <el-form-item label="用户名">
              <el-input v-model="editUserForm.userName" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item>
            <el-form-item label="手机号">
              <el-input v-model="editUserForm.mobilePhone" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item>
          </el-row>
          <el-row>
             <!-- <el-form-item label="原密码">
              <el-input v-model="editUserForm.passWord" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item> -->
            <el-form-item prop="passWord1" label="新密码">
              <el-input type="password" v-model="editUserForm.passWord1" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item prop="passWord2" label="确认新密码">
              <el-input type="password" v-model="editUserForm.passWord2" autocomplete="off"></el-input>
            </el-form-item>
          </el-row>

        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="info" @click="editUserFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="editUserPassWd('editForms')">确 定</el-button>
        </div>
      </el-dialog>
       <transition name="el-zoom-in-bottom">
      <div  v-show="tipToast" class="transition-box">
        <p class="title">
          【新】消息提示
          <span @click="tipToast=false" class="el-icon-close"></span>
        </p>
         <p @click="pagenew" class="content">
            {{objData.title}}
          </p>
      </div>
    </transition>
  </el-container>
</template>

<script>
import { handleSpeak, IsPhone } from '@/utils/speechSynthesis.js'

var validatePass = (rule, value, callback) => {
  // 必须包含⼤⼩写字母、数字、特殊字符长度再9-16位之间
  var regex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{9,16}')
  if (value === '') {
    callback(new Error('请输⼊密码'))
  } else if (value.length < 8) {
    callback(new Error('密码最少8位'))
  } else if (!regex.test(value)) {
    callback(new Error('密码必须同时包含字母、数字和特殊字符其中三项且⾄少8位'))
  } else {
    callback()
  }
}
export default {
  name: 'Layout',
  components: {
  },
  data () {
    return {
	  logoSrc: require('@/fonts/logo@2x.png'),
      disabled: true,
      editUserFormVisible: false,
      userName: sessionStorage.getItem('userName') || '未登录',
      title: '首页',
      nav: [],
      home: '',
      operation: '',
      system: '',
      largeScreen: '',
      editUserForm: {
        userName: '', mobilePhone: '', passWord: '', passWord1: '', passWord2: ''
      },
      editRules: {
        passWord1: [
          { required: true, validator: validatePass, trigger: 'blur' }
        ],
        passWord2: [
          { required: true, validator: validatePass, trigger: 'blur' }
        ]
      },
      objData: '',
      tipToast: false,
      ws: '',
      timer: '' // 过期时间

    }
  },
  created () {
    // setTimeout(() => {
    // this.$nextTick(function () {
    this.getNav()
    // })
    // }, 0)
  },
  mounted () {
    console.log(this.ws, 'this.ws')
    this.init()
  },
  methods: {
    editUserTap () {
      this.editUserForm.userName = sessionStorage.getItem('userName')
      this.editUserForm.mobilePhone = sessionStorage.getItem('phone')
      this.editUserForm.passWord = sessionStorage.getItem('passwd')
      this.editUserFormVisible = true
    },
    async editUserPassWd (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
        //   if (!this.editUserForm.passWord1) {
        //     this.$message.error('新密码不能为空！')
        //     return false
        //   }
        //   if (this.editUserForm.passWord1.trim().length < 3 || this.editUserForm.passWord1.trim().length > 18) {
        //     this.$message.error('密码长度应为3-18个字符')
        //     return false
        //   }
          if (this.editUserForm.passWord1 != this.editUserForm.passWord2 && this.editUserForm.passWord1) {
            this.$message.error('两次密码输入不一致，请重新输入！')
            return false
          }
          console.log(this.editUserForm)
          const { data: res } = await this.$http.post('userManageServer/user/updateUserPassWord', {
            userName: this.editUserForm.userName,
            mobilePhone: this.editUserForm.mobilePhone,
            passWord: this.editUserForm.passWord1
          })
          if (res.statusCode !== '200') return this.$message.error('修改密码失败')
          // 退出登录
          this.editUserFormVisible = false
          this.$message.success('修改密码成功,请重新登录')
          setTimeout(() => {
            this.exit()
          }, 2000)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // home() {
    //   // this.title = '首页'
    //   this.$router.push('/home')
    // },
    // operation() {
    //   // this.title = '运营管理'
    //   this.$router.push('/operation')
    // },
    // system() {
    //   // this.title = '系统管理'
    //   this.$router.push('/system')
    // },
    statusEliminate (str) {
      if (str == '运营管理') {
        this.$route.path != '/operation' ? this.$router.push('/operation') : ''
      } else if (str == '系统管理') {
        this.$route.path != '/system' ? this.$router.push('/system') : ''
      } else if (str == '首页') {
        this.$route.path != '/home' ? this.$router.push('/home') : ''
      }
    },
    toBigscreen () {
      this.$router.push('/bigScreen')
    },
    exit () {
      sessionStorage.clear()
      this.$store.dispatch('CLEAR')
      this.$router.push('/login')
    },
    getNav () {
      this.nav = JSON.parse(sessionStorage.getItem('headerNav'))
      // console.log(this.nav, 'this.nav')
      //   console.log(this.nav, 'this.nav')
      if (this.nav instanceof Array) {
        this.home = this.nav.filter(function (item, index, array) {
          return item.menuUrl === '/home'
        })

        this.operation = this.nav.filter(function (item, index, array) {
          return item.menuUrl === '/operation'
        })
        // console.log(this.operation);

        this.system = this.nav.filter(function (item, index, array) {
          return item.menuUrl === '/system'
        })
        this.largeScreen = this.nav.filter(function (item, index, array) {
          return item.menuUrl === '/bigScreen'
        })
        // console.log(this.largeScreen, 'this.largeScreen')
      }
      sessionStorage.setItem('menuList', JSON.stringify(this.system[0].twoMenusList))
      // console.log('走了layout');
    },
    // websocket初始化
    init () {
      const userId = sessionStorage.getItem('userId')
      if ('WebSocket' in window) {
        this.ws = new WebSocket(process.env.VUE_APP_WS_API + 'pmService/myWs?userId=' + userId)
        // this.ws = new WebSocket('wss://zyc.hn12320.cn/api/pmService/myWs')
        // 监听socket连接
        this.ws.onopen = this.open
        // 监听socket错误信息
        this.ws.onerror = this.error
        // 监听socket消息
        this.ws.onmessage = this.getMessage
        // 发送消息
        // let data {xxx: xxx}
        // this.ws.onmessage = this.send(JSON.stringify())
        // 断开
        this.ws.onclose = this.close
      } else {
        alert('当前浏览器 Not support websocket')
      }
    },
    open: function () {
      console.log('ws调用连接成功回调方法')
      this.timer = window.setInterval(() => { // 每隔5秒钟发送一次心跳，避免websocket连接因超时而自动断开
        console.log('每隔50秒钟发送一次心跳')
        var ping = { type: 'pingpong' }
        this.ws.send(JSON.stringify(ping))
      }, 50000)
    },
    error: function () {
      console.log('WebSocket连接发生错误')
    },
    getMessage (msg) {
      if (msg.data) {
        if (typeof msg.data === 'string') {
          console.log('收到信息:' + msg.data)
          if (msg.data.includes('pingpong')) {
            return false
          }
          const str = JSON.parse(msg.data)
          this.objData = str
          this.tipToast = true
          if (!IsPhone()) {
            handleSpeak(str.title)
          }
          const route = this.$route.path
          if (route === '/operation/dispatch/orderScheduling' || route === '/system/hospitalManagement/Information') {
            // this.pagenew()
            if (route === '/system/hospitalManagement/Information') {
              this.$eventBus.$emit('message')
            } else {
              this.$eventBus.$emit('messageOder')
            }
            // this.$router.go(0)
          }
        //   this.pagenew()
        }
      }
    },
    // send: function () {
    //   try {
    //     if (this.msg) {
    //       this.ws.send(this.msg)
    //     //   this.setMessageInnerHTML(this.msg)
    //     }
    //   } catch (error) {
    //     // this.setMessageInnerHTML('socket已经关闭')
    //   }
    // },
    close: function (e) {
      console.log(e, 'socket已经关闭')
      clearInterval(this.timer)
    },
    // 将消息显示在网页上
    // setMessageInnerHTML (innerHTML) {
    //   document.getElementById('message').innerHTML += innerHTML + '<br/>'
    // }
    playSound () {
      var url = 'http://img.i12320.com/newOrder.mp3'
      var n = new Audio(url)
      n.src = url
      n.play() // 播放阅读
    },
    pagenew () {
      if (this.objData.category == 1) {
        this.$router.push('/operation/dispatch/orderScheduling')
      } else {
        this.$router.push('/system/hospitalManagement/Information')
      }
      this.tipToast = false
    }

  },
  beforeDestroy () {
    if (this.ws) {
      this.ws.close()
    }
    clearInterval(this.timer)
  }
}
</script>

<style lang="less">
.father {
  height: 100%;

  width: 100%;
//   overflow: hidden;
//   ::-webkit-scrollbar {
//     display: none;
//   }
  .el-header,
  .el-footer {
    background-color: #b3c0d1;
    padding: 0;
  }
  .main {
    margin-top: 1.76rem;
    margin-bottom: 0.45rem;
    // height: 500px;
    // width: 100%;
    // height: 100%;
    overflow-y: scroll;
    // margin-top: 1.75rem;
  }
  // 头部样式
  .el-header {
    padding: 0;
    height: auto !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    // z-index: 9999999999999;
    // 上头部
    .header-top {
      display: flex;
      align-items: center;
      height: 1rem;
      background-color: #2196fb;
      background: url('../../fonts/top_bar_bg.png') no-repeat;
      background-size: cover;
      span {
        color: #fff;
        font-size: 18px;
      }
      // 上头部左边
      .header-left {
        margin-left: 0.625rem;
        img {
          margin-right: 0.5375rem;
          width: 1.2375rem;
          height: 0.675rem;
        }
        span.title {
          color: #fff;
          font-size: 24px;
          font-weight: bold;
        }
      }
      // 上头部中间
      .header-center {
        display: flex;
        justify-content: space-between;
        margin-left: 0.9875rem;
        img {
          margin-right: 0.15rem;
        }
        .operation {
          margin: 0 1.1875rem 0 1.05rem;
        }
        .home,
        .operation,
        .setting {
          display: flex;
          align-items: center;
          // margin-left: 10px;
          cursor: pointer;
          img {
            width: 0.525rem;
            height: 0.525rem;
          }
        }
      }
      // 上头部右边
      .header-right {
        margin-left: auto;
        margin-right: 0.375rem;
        display: flex;
        align-items: center;
        img {
          margin-right: 0.25rem;
          width: 0.525rem;
          height: 0.525rem;
        }
        .exit {
          font-size: 14px;
          display: inline-block;
          margin: 0 5px;
          cursor: pointer;
        }
      }
    }

    // 下头部
    .header-under {
      height: 0.75rem;
      display: flex;
      align-items: center;
      padding-left: 0.25rem;
      background-color: #fff;
      position: relative;
      z-index: 5;
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3);
      .header-under-btn {
        border-radius: .25rem;
        padding: .15rem .2875rem;
        margin-left: auto;
        margin-right: .125rem;
      }
      span {
        color: #0097fe;
        font-weight: bold;
        font-size: 20px;
      }
      i {
        display: inline-block;
        width: 0.3rem;
        height: 0.3rem;
        background: url('../../fonts/ic_home .png') no-repeat center;
        background-size: cover;
        margin-right: 0.1875rem;
      }
      // 下头部左边
      .under-left {
        display: flex;
        align-items: center;
        :nth-child(3) {
          margin-left: 0.1875rem;
        }
        :nth-child(3)::before {
          content: '|';
          display: inline-block;
          color: #0096fc;
          height: 0.3rem;
          width: 2px;
          margin-right: 0.1875rem;
        }
      }
    }
  }
  // 底部区域样式
  .el-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.45rem !important;
    font-size: 12px;
    padding-right: 0.75rem;
    color: #222222;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #fff;
    // user-select: none;
    z-index: 9999999999999;
  }
}
.layout-modify{
 .el-row {
          display: flex;
          .el-form-item {
            flex: 1;
            display: flex;
            .el-form-item__content {
              flex: 1;
              .el-date-editor {
                width: 100%;
              }
              .el-input {
                width: 100%;
                .el-input__inner {
                  width: 100%;
                }
              }
              .el-select {
                width: 100%;
              }
            }
          }
        }
}
.transition-box {
  position: absolute;
  // border: 1px solid red;
  right: 10px;
  // top:0px;
  bottom: 0px;
  width: 400px;
  // background-color: pink;
  border: 4px solid #1e90ff;
  height: 150px;
  z-index: 999;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 30px;
    text-align: center;
    // margin-bottom: 10px;
    background-color: #1e90ff;
    font-weight: 700;
    font-size: 14px;
    line-height: 30px;

    span {
      width: 20px;
      height: 20px;
      display: inline-block;
      // background-color: red;
      margin-right: 10px;
    }
  }

  .content {
    width: 100%;
    height: 90px;
    padding-left: 5px;
    background-color: #fff;
    padding-top: 10px;
    cursor: pointer;
  }
}
</style>
