const synth = window.speechSynthesis
let msg = {}
try {
  msg = new SpeechSynthesisUtterance()
} catch {
}

function handleSpeak (text) {
  console.log(msg, text, 'text')
  msg.text = text // 文字内容: 小朋友，你是否有很多问号
  msg.lang = 'zh-CN' // 使用的语言:中文
  msg.volume = 1 // 声音音量：1
  msg.rate = 1 // 语速：1
  msg.pitch = 1 // 音高：1
  synth.speak(msg) // 播放
}
let timeout = null

function debounce (func, wait = 300, immediate = false) {
  // 清除定时器
  if (timeout !== null) clearTimeout(timeout)

  if (immediate) {
    // 立即执行，此类情况一般用不到
    var callNow = !timeout
    timeout = setTimeout(function () {
      timeout = null
    }, wait)
    if (callNow) typeof func === 'function' && func()
  } else {
    // 设置定时器，当最后一次操作后，timeout不会再被清除，所以在延时wait毫秒后执行func回调方法
    timeout = setTimeout(function () {
      typeof func === 'function' && func()
    }, wait)
  }
}

// 手机或是pc
function IsPhone () {
  // 获取浏览器navigator对象的userAgent属性（浏览器用于HTTP请求的用户代理头的值）
  var info = navigator.userAgent
  // 通过正则表达式的test方法判断是否包含“Mobile”字符串
  var isPhone = /mobile/i.test(info)
  // 如果包含“Mobile”（是手机设备）则返回true
  return isPhone
}
export {
  handleSpeak,
  debounce,
  IsPhone
}
